import React from 'react'
import { Layout } from '../components/LayoutLanding'
import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { SEO } from '../components/SEO'

const Terms = () => (
  <Layout>
    <SEO
      title="Vanna | Termos de Uso"
      tags={[
        'Cobranças simples',
        'Cobranças recorrentes',
        'Segurança',
        'Pix',
        'Rapidez',
        'Receba em segundos',
      ]}
      description="A política de privacidade em que você aceita ao utilizar nossa plataforma"
      canonical="https://vanna.app/termos-uso/"
    />
    <LandingTopbar />
    <Links />
    <section className="my-16 px-5 md:px-8 lg:px-16">
      <div className="md:max-w-3xl md:mx-auto md:text-center mb-20">
        <h1 className="text-gray-900 text-3xl leading-9 font-heading font-medium lg:text-4xl">
          Termos de uso.
        </h1>
        <p className="my-4 text-gray-800 leading-6 sm:text-xl lg:text-lg xl:text-xl">
          Verifique abaixo nosso termos de uso que você aceita ao utilizar a
          plataforma. Aqui você entende todas nossas regras e obrigatoriedades.
        </p>
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="/images/logo-s.png" alt="Logo" />
        </div>
      </div>

      <p className="pb-4">Última atualização em 06/03/2021</p>
      <h2 className="my-4 font-bold">Introdução</h2>
      <p className="pb-4">
        A Vanna é um produto desenvolvido e criado pela empresa Vanna
        Desenvolvimento LTDA, em que fornece cobranças via o meio de pagamento
        Pix para seus usuários por intermédio da empresa devidamente registrada
        para esse fim Gerencianet LTDA. O produta Vanna oferece ferramentas para
        que empresas possam cobrar seus clientes de forma rápida através de
        pagamentos instantâneos. Os usuários do produto devem estar cientes e
        cumprir com os termos e condições aqui descritos.
      </p>
      <p className="pb-4">
        Definições para interpretar os termos de uso e condições:
      </p>
      <ol className="pb-4">
        <li>
          - Usuário: responsável pela empresa que se cadastrou no produto e que
          realizou o aceite deste termos de uso.
        </li>
        <li>
          - Plataforma, produto, Vanna, nosso, nós: refere-se ao produta Vanna
          em que aqui descrevemos seus termos de uso.
        </li>
        <li>
          - Conta Pix: carteira digital utilizada para analisar recursos
          advindos de cobranças.
        </li>
        <li>
          - Cobranças instantâneas: cobranças para clientes utilizando o meio de
          pagamento Pix.
        </li>
        <li>
          - Pix: sistema de pagamentos instantâneos, desenvolvido e sustentado
          pelo Banco Central do Brasil o qual permite cobranças, transferências
          em 10 segundos, 24/7 todos os dias do ano.
        </li>
        <li>
          - Chave CNPJ: Identificador de uma conta transacional Pix. Essa chave
          como CNPJ deve ser registrada em um banco da escolha do Usuário que
          possui registro.
        </li>
        <li>
          - Canal de atendimento: formas de atendimento no qual nos conectamos
          com os usuários do produto.
        </li>
      </ol>
      <p className="pb-4">
        AO UTILIZAR O VANNA VOCÊ AUTOMATICAMENTE ESTÁ DE ACORDO COM OS TERMOS E
        CASO NÃO CONCORDE COM ALGUMA CONDIÇÃO OU TERMOS, NÃO UTILIZE O PRODUTO
        VANNA.
      </p>
      <h2 className="my-4 font-bold">Condições gerais</h2>
      <ol>
        <li>
          - Ao usar a plataforma o Usuário entende que vai passar por um
          processo de registro onde poderá ou não ser aceito para utilizar a
          plataforma com todas suas funcionalidades e reservamos o direito de
          buscar todos os meios lícitos para realizar esse processo.
        </li>
        <li>
          - O Usuário responsável legal por sua empresa deverá fornecer todos
          dados válidos para realizar um registro na plataforma;
        </li>
        <li>
          - Ao utilizar a plataforma o usuário aceita os termos de uso aqui
          descritos, a política de privacidade e nosso padrão de segurança.
        </li>
        <li>
          - O Usuário é inteiramente responsável por todos os dados registrados
          na plataforma sem qualquer responsabilidade da Vanna por esse
          conteúdo;
        </li>
        <li>
          - O Usuário poderá utilizar a plataforma apenas para cobranças
          relacionadas à atividade comercial, vinculando-se à legislação em que
          se permite cobrar pelo Pix;
        </li>
        <li>
          - A plataforma não se responsabiliza por nenhum erro(bug) de software
          tanto do parceiro como da própria plataforma. Mas trabalhará com
          afinco para solucioná-lo com rapidez;
        </li>
        <li>
          - A plataforma não se responsabiliza por clientes do Usuário que não
          pagam suas cobranças;
        </li>
        <li>
          - A plataforma tem por premissa oferecer ferramentas que auxiliam na
          cobrança e gerenciamento de clientes para empresas;
        </li>
        <li>
          - O Usuário não pode intervir, alterar os dizeres no site ou no
          aplicativo para infringir a lei ou imprimir falso testemunho, com
          sujeição à lei pelo ato;
        </li>
        <li>
          - A plataforma não é responsável por nenhuma perda ou dano fora do
          nosso alcance;
        </li>
        <li>
          - O Usuário não pode fazer nada ameaçador, abusivo, ofensivo,
          difamatório, calunioso, tortuoso, obsceno, profano ou invasivo da
          privacidade de outra pessoa na plataforma;
        </li>
        <li>
          - O Usuário é inteiramente responsável por criar uma senha segura e
          forte e não permitir que outras pessoas acessem sua conta. Se faz
          opcional mas muito recomendado a utilização da funcionalidade de
          autenticação em 2 fatores, em que o Usuário pode escolher receber um
          token via SMS ou aplicativo autenticador gerador de tokens;
        </li>
        <li>
          - Não é permitido o aluguel ou venda do acesso a terceiros da
          plataforma pelo Usuário registrado;
        </li>
        <li>
          - Todos os dados registrados pelo Usuário serão considerados válidos,
          por isso é de responsabilidade do Usuário atualizar seus dados com
          toda frequência necessária;
        </li>
        <li>
          - A utilização da plataforma pode ser interrompida por problemas de
          software ou provisionamento e o Usuário deve estar ciente de que todos
          os esforços estarão em resolver o problema para dar continuidade a
          disponibilidade da plataforma;
        </li>
        <li>
          - O Usuário utiliza a plataforma por conta e risco e está de acordo
          com todos os pontos aqui descritos;
        </li>
        <li>
          - O Usuário se compromete a defender, indenizar e isentar de qualquer
          responsabilidade a Vanna, subsidiárias, seus executivos,,
          representantes, sucessores, prepostos, administradores, prestadores de
          serviços, agentes, fornecedores e/ou funcionários com relação a toda e
          qualquer reclamação, perda, dano, obrigação, custos, dívidas ou
          despesas (incluindo, dentre outros, honorários advocatícios e custas
          processuais) incorridas em razão de: (i) mau uso das cobranças
          emitidas; (ii) violação e/ou não observância de quaisquer dispositivos
          destes termos de uso; (iii) violação de qualquer direito de terceiros.
          As obrigações de defesa e indenização permanecerão em vigência mesmo
          após a cessação do uso e/ou acesso da plataforma.
        </li>
        <li>
          - Nossa equipe poderá entrar em contato para atualizar ou confirmar
          informações. Fique atento pois nunca será perguntado sobre sua senha
          ou valores em sua conta;
        </li>
        <li>
          - Este termo de uso substitui qual conversa e ou troca de informações
          previamente discutidas entre o Usuário e a Vanna;
        </li>
        <li>
          - O Usuário está ciente de todas as leis que regem sua atividade;
        </li>
        <li>
          - Toda e qualquer violação destes termos de uso sobreviverão à remoção
          do Usuário da plataforma;
        </li>
        <li>
          - A Vanna reserva-se no direito de reter taxas e repassar valores
          parciais referentes a utilização da plataforma;
        </li>
        <li>
          - Para utilizar a API e SDK o Usuário deve disponibilizar esse termo
          para seus clientes aceitarem.
        </li>
        <li>
          - Os valores cobrados pela plataforma estão descritos na página
          https://vanna.app/tarifas e podem ser alterados sem aviso prévio;
        </li>
        <li>
          - A Vanna reserva-se no direito de cobrar uma mensalidade pela
          utilização de funcionalidades específicas da plataforma com
          consentimento do Usuário;
        </li>
        <li>
          - No caso de alguma decisão judicial anular alguma informação aqui
          descrita, Usuário e Plataforma devem encontrar outra maneira de seguir
          com o resultado esperado.
        </li>
        <li>
          - O Usuário também se compromete a seguir os termos de uso do nosso
          parceiro Gerencianet S/A, o qual pode acessado aqui:
          https://gerencianet.com.br/termos-de-uso/ com as devidas proporções às
          funcionalidades em que oferecemos.
        </li>
      </ol>

      <h2 className="my-4 font-bold">Funcionalidades Vanna</h2>

      <p className="pb-4">
        O produta Vanna disponibiliza ferramentas para empresas cobrarem seus
        clientes de forma automatizada por meio do meio de pagamento Pix, o qual
        é disponibilizado e gerenciado pelo Banco Central do Brasil por meio de
        parceria com a Gerencianet S/A.
      </p>

      <p className="pb-4">
        Nosso motor de cobranças pode realizá-las em diversos períodos
        pré-estabelecidos e nosso motor de notificações lembra o cliente do
        Usuário a pagar a cobrança na data correta.
      </p>

      <p className="pb-4">
        Além da cobrança em si, a plataforma oferece a possibilidade de registro
        de clientes, produtos, serviços e recorrências.
      </p>

      <p className="pb-4">
        A Plataforma Vanna também permite o uso de forma programática através de
        APIs, webhooks e SDKs.
      </p>

      <h2 className="my-4 font-bold">Cobrança pela utilização Vanna</h2>

      <p className="pb-4">
        A plataforma Vanna reserva-se o direito de cobrar taxas cumulativas
        pelas operações oferecidas assim como mensalidades para possíveis
        ferramentas disponibilizadas ao Usuário.
      </p>

      <p className="pb-4">
        As taxas poderão ser mudadas sem aviso prévio e serão cobradas logo
        depois do anúncio a partir da transação/operação conseguinte.
      </p>

      <p className="pb-4">
        São cobradas taxas por cobranças não efetuadas. Essas taxas serão cobradas
        mensalmente no mês seguinte ao uso.
      </p>

      <p className="pb-4">
        As taxas e valores poderão ser alterados a depender de negociação com o
        Usuário.
      </p>

      <p className="pb-4">
        No presente momento deste documento serão cobradas taxas para: geração
        de cobrança e devoluções.
      </p>
      <p className="pb-4">
        Em caso de cancelamento da conta, os valores devidos serão retidos pela
        plataforma e os valores parciais enviados para conta Pix cadastrada no
        CNPJ do Usuário.
      </p>

      <h2 className="my-4 font-bold">Representante Vanna</h2>

      <p className="pb-4">
        O Representante Vanna é uma forma de remuneração para nossos Usuários
        que indicam nossa plataforma para amigos e a quem for necessário a
        cobrança instantânea. Para isso, a cada transação, o Representante Vanna
        recebe 20 centavos de remuneração.
      </p>
      <p className="pb-4">
        A remuneração pode ser alterada, reajustada ou removida sem aviso
        prévio, a qualquer tempo, assim como os links de cadastro de novos
        Usuários.
      </p>
      <p className="pb-4">
        As taxas e prazos poderão ser diferentes entre indicador e indicado no
        modo que melhor for conveniente para a expansão da plataforma.
      </p>
      <p className="pb-4">
        Caso haja fraude ou revenda de links o Usuário deverá ressarcir
        possíveis prejuízos, custos ou despesas, corrigidos monetariamente,
        segundo o índice de variação do IGP-M/FGV, ou seu eventual substituto,
        no período compreendido entre a data do desembolso pela plataforma e sua
        efetiva devolução.
      </p>
      <p className="pb-4">
        Para cumprir o ressarcimento será enviado uma cobrança Pix com os
        valores ajustados.
      </p>

      <p className="pb-4">
        Caso a plataforma cancele essa forma de remuneração para seus Usuários,
        os valores já recebidos e a receber não serão prejudicados.
      </p>

      <h2 className="my-4 font-bold">Bloqueio de conta</h2>

      <p className="pb-4">
        O bloqueio da conta pode ser gerado por movimentações atípicas e fora do
        padrão julgado pelas regras de segurança da plataforma. Se sua conta foi
        bloqueada, possivelmente recebeu um e-mail com o motivo, caso contrário
        envie um email para oi@vanna.app explicando o que aconteceu.
      </p>

      <h2 className="my-4 font-bold">Banimento da plataforma</h2>

      <p className="pb-4">
        O usuário poderá ser banido da plataforma por qualquer motivo que o
        corpo de decisores julgar necessário. Toda e qualquer decisão será
        comunicada para o Usuário por e-mail.
      </p>

      <h2 className="my-4 font-bold">Recuperação da conta</h2>

      <p className="pb-4">
        Caso você tenha perdido sua senha, entre no{' '}
        <a
          href="https://conta.vanna.app/esqueci_minha_senha"
          target="_blank"
          rel="noopener"
        >
          aqui
        </a>{' '}
        e realize o procedimento. Sua nova senha será recuperada via e-mail.
      </p>
      <p className="pb-4">
        Caso tenha perdido o aplicativo gerador de tokens ou seu número, entre
        em contato urgente no oi@vanna.app com o assunto “Perdi 2FA” com seu
        nome completo, cpf, cnpj e selfie segurando seu RG/CNH e uma folha com a
        data do dia de envio do email e sua assinatura.
      </p>

      <h2 className="my-4 font-bold">Remoção de seus dados da plataforma</h2>

      <p className="pb-4">
        O Usuário pode solicitar a remoção de sua conta e seus dados da
        plataforma Vanna quando quiser. Apenas deve enviar um email para
        oi@vanna.app com o título “Remover minha conta” e seu CNPJ no corpo do
        email para que nosso time dispare a rotina para remoção total. Nosso
        time tem como medida de segurança ligar para Usuários que ainda possuam
        recursos na plataforma para ajudar com transferências.
      </p>

      <h2 className="my-4 font-bold">Propriedade intelectual</h2>

      <p className="pb-4">
        A Vanna possui todas as licenças ou os direitos autorais sobre os
        layouts, código-fonte, bancos de dados, know-how, textos, nomes de
        domínio, gráficos, fotografias, imagens, vídeos, áudios, marcas,
        logomarcas, dentre outros elementos e materiais que se encontram
        protegidos por direitos de propriedade industrial e direitos autorais,
        em que apenas a nós possuímos o direito de usar, comercializar,
        explorar, reproduzir, ou licenciar a terceiros. Ao cadastrar-se, o
        Usuário se compromete a respeitar os direitos de propriedade intelectual
        Vanna e de terceiros que estejam, estiveram ou estarão presentes.
        Nenhuma propriedade intelectual é disponibilizada apenas ao registrar e
        acessar a Plataforma.
      </p>

      <h2 className="my-4 font-bold">Segurança e acesso</h2>

      <p className="pb-4">
        A equipe de desenvolvedores e de SRE monitora frequentemente a
        plataforma para mantê-la segura e com performance incrível para você
        Usuário.
      </p>

      <p className="pb-4">
        O Usuário é responsável por cuidar com muito zelo de sua conta,
        utilizando-se de senhas fortes e a autenticação em 2 fatores
        disponibilizada na plataforma.
      </p>
      <p className="pb-4">
        O acesso deve ser feito apenas pelo responsável pela conta e caso
        encontre movimentações desconhecidas deverá entrar em contato
        imediatamente via o email oi@vanna.app.
      </p>

      <h2 className="my-4 font-bold">Privacidade</h2>

      <p className="pb-4">
        A privacidade de nossos usuários é levada muito a sério, você pode saber
        mais sobre nossas políticas de privacidade acessando
        <a
          href="https://vanna.app/politica-privacidade"
          target="_blank"
          rel="noopener"
        >
          aqui
        </a>
        .
      </p>

      <h2 className="my-4 font-bold">Foro</h2>

      <p className="pb-4">
        O foro eleito para resolver qualquer problema é o Foro da Comarca de
        Sorocaba, Estado de São Paulo, com renúncia expressa a qualquer outro,
        por mais privilegiado que seja este.
      </p>

      <h2 className="my-4 font-bold">Modificações nos termos de uso</h2>

      <p className="pb-4">
        Os termos poderão ser alterados a qualquer momento e por qualquer
        motivo. Um aviso via e-mail será enviado para o Usuário responsável com
        a data para entrada em vigor das alterações. Ao continuar com a
        manutenção do uso do produto, o Usuário aceita as alterações dos termos
        de uso. Caso não haja pendências, poderá solicitar o cancelamento e
        remoção de seus dados da plataforma se não concordar com as mudanças
        estabelecidas.
      </p>

      <h2 className="my-4 font-bold">Contato</h2>

      <p className="pb-4">
        Você pode entrar em contato através do email oi@vanna.app para saber
        mais sobre nosso produto ou tirar dúvidas sobre os termos e disposições
        aqui descritos.
      </p>
    </section>
  </Layout>
)

export default Terms
